import http from '@/utils/http';

/**
 * 添加日志
 * @param {*} data
 * @returns
 */
export function addLog(data) {
  return http.post('/txn/log', data);
}
