<template>
  <div>
    <div id="googlePay"></div>
    <el-button type="primary" @click="refresh">刷新</el-button>
    <div id="appPay" v-if="applePayLoaded">
      <apple-pay-button buttonstyle="black" type="plain" :locale="APPLE_PAY_LANG[app.currentLan]" @click="initApplePay2"></apple-pay-button>
    </div>
    <div>
      <el-button @click="initApplePay1">Init Apple Pay</el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { APPLE_PAY_LANG, ApplePay } from '@/utils/tools/apple_pay';
import { GooglePay } from '@/utils/tools/google_pay';

export default {
  data() {
    return {
      applePayLoaded: null,
      APPLE_PAY_LANG,
    };
  },
  computed: {
    ...mapGetters(['app']),
  },
  beforeCreate() {
    // Google Pay
    const script1 = document.createElement('script');
    script1.src = 'https://pay.google.com/gp/p/js/pay.js';
    script1.onload = () => {
      this.init();
    };
    document.body.appendChild(script1);
    // Apple Pay
    const script2 = document.createElement('script');
    script2.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js';
    script2.onload = () => {
      this.applePayLoaded = true;
    };
    document.body.appendChild(script2);
  },
  methods: {
    refresh() {
      this.init();
    },
    init() {
      const { GOOGLE_PAY } = require('@/config/env.' + process.env.NODE_ENV);
      const transactionInfo = {
        countryCode: 'US',
        currencyCode: 'USD',
        totalPriceStatus: 'FINAL',
        // set to cart total
        totalPrice: '1.00',
      };
      GooglePay.init(GOOGLE_PAY, { gatewayName: 'ronghan', transactionInfo, merchantId: 'merchantId', language: this.app.currentLan, website: '' }, '#googlePay', null, null);
    },
    initApplePay1() {
      if (this.applePayLoaded) {
        ApplePay.initButton(this.app.currentLan);
      }
    },
    initApplePay2() {
      if (this.applePayLoaded) {
        const request = {
          countryCode: 'US',
          currencyCode: 'USD',
          supportedNetworks: ['visa', 'masterCard', 'amex', 'discover', 'chinaUnionPay'],
          merchantCapabilities: ['supports3DS'],
          total: { label: 'Your Merchant Name', type: 'final', amount: '0.01' },
        };
        ApplePay.startPay(request, 'merchant.ronhan.pacypay', null);
      }
    },
  },
};
</script>
